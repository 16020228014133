import Vue from 'vue';
import {
  addProjectToFavorites,
  deleteProjectFromFavorites,
  getProjects,
  loadProjectImage,
} from '@/services/api/projects.api';
import { waitForVuex } from '@/utils/vuex';

const state = {
  projects: [],
  projectImages: {},
  projectsStatus: '',
  masterProject: undefined,
};
const mutations = {
  projects_request(state) {
    state.projectsStatus = 'loading';
  },
  projects_success(state, projects) {
    state.projects = projects;
    state.projectsStatus = 'success';
  },
  projects_request_finish(state) {
    state.projectsStatus = 'success';
  },
  projects_create_success(state, project) {
    state.projects.push(project);
    state.projectsStatus = 'success';
  },
  projects_update_success(state, updatedProject) {
    const index = state.projects.findIndex(
      (project) => project.id === updatedProject.id
    );
    if (index >= 0) {
      Vue.set(state.projects, index, {
        ...(state.projects[index] || {}),
        ...updatedProject,
      });
    }
    state.projectsStatus = 'success';
  },
  projects_delete_success(state, deletedProject) {
    state.projects = [
      ...state.projects.filter((project) => project.id !== deletedProject.id),
    ];
    state.projectsStatus = 'success';
  },
  set_master_project(state, project) {
    state.masterProject = project;
  },
  reset_project_state(state) {
    state.projectsStatus = '';
    state.projects = [];
    state.masterProject = undefined;
  },
  projects_image_add_cache(state, { id, image }) {
    state.projectImages[id] = image;
  },
};
const actions = {
  fetchProjects({ commit, state, getters }, { loadImages } = {}) {
    if (state.projects.length === 0) {
      commit('projects_request');
    }
    return getProjects({
      loadImages: loadImages === false,
      'filter[license]': getters.selectedLicense.id,
    })
      .then((projects) => {
        commit('projects_success', projects);
      })
      .catch((error) => {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  reloadProjects({ commit, state, getters }, { loadImages } = {}) {
    return getProjects({
      loadImages: loadImages === false,
      'filter[license]': getters.selectedLicense.id,
    })
      .then((projects) => {
        commit('projects_success', projects);
      })
      .catch((error) => {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  loadProjectImage({ commit, state, getters }, project) {
    const imageId = project.image?.id || project.image;
    const image = state.projectImages[imageId];
    if (image?.id) {
      commit('projects_update_success', { id: project.id, image });
      return image;
    } else if (image) {
      return;
    }
    const promise = loadProjectImage(project.id, imageId)
      .then((image) => {
        commit('projects_image_add_cache', { id: imageId, image });
        commit('projects_update_success', { id: project.id, image });
        return image;
      })
      .catch((error) => {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
    commit('projects_image_add_cache', { id: imageId, image: promise });
    return promise;
  },
  addProjectToFavorites: waitForVuex(
    (_, projectId) => `project.favorite.add.${projectId}`,
    ({ commit, getters }, projectId) => {
      return addProjectToFavorites(projectId, {
        'license[id]': getters.selectedLicense.id,
      })
        .then((res) => {
          commit('showNotification', {
            content: res.message,
            color: 'success',
          });
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  deleteProjectFromFavorites: waitForVuex(
    (_, projectId) => `project.favorite.delete.${projectId}`,
    ({ commit, getters }, projectId) => {
      return deleteProjectFromFavorites(projectId, {
        'license[id]': getters.selectedLicense.id,
      })
        .then((res) => {
          commit('showNotification', {
            content: res.message,
            color: 'success',
          });
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
};
const getters = {
  projects: (state) => state.projects,
  projectsStatus: (state) => state.projectsStatus,
  masterProject: (state) => state.masterProject,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
