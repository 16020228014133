import httpClient from '../httpClient';

const getProjects = (filters) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get('/projects', {
        params: filters,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getProject = (id) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const findProjectBySlug = (slug) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`projects/slug/${slug}/find`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createProject = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateProject = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/project/${data.id}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteProject = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProjectLicenseUsers = (id) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${id}/licenseUsers`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProjectMembersWithTaskPermissions = (id) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${id}/members`, {
        params: {
          'filter[tasks]': true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProjectMembers = (id) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${id}/members`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loadProjectImage = (id, imageId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`project/${id}/image/${imageId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const addProjectToFavorites = (projectId, params) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/project/${projectId}/favorite`, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteProjectFromFavorites = (projectId, params) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/favorite`, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  getProjectLicenseUsers,
  loadProjectImage,
  getProjectMembersWithTaskPermissions,
  getProjectMembers,
  addProjectToFavorites,
  deleteProjectFromFavorites,
};
